import rsvImage from "src/assets/images/rsv.png";

import DownloadCard from "./DownloadCard";
import type { LinkComponentProps } from "./links";

const datasets = [
  "rsvKits",
  "rsvCountyInterpolated",
  "rsvCountyAverage",
  "rsvStateAverage",
  "rsvRegionalAverage",
  "rsvNationalAverage",
] as const;

interface RsvDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const RsvDownloads = (props: RsvDownloadsProps) => (
  <DownloadCard {...props} datasets={datasets} title="RSV" image={rsvImage} />
);

export default RsvDownloads;
