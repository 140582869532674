import mpoxImage from "src/assets/images/mpx_virus.png";

import DownloadCard from "./DownloadCard";
import type { LinkComponentProps } from "./links";

const datasets = ["mpoxNWSS"] as const;

interface MpoxDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const MpoxDownloads = (props: MpoxDownloadsProps) => (
  <DownloadCard {...props} datasets={datasets} title="Mpox" image={mpoxImage} />
);

export default MpoxDownloads;
