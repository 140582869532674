import { Navigate, Outlet } from "react-router-dom";

import { FeatureFlags, useFeatureFlags } from "src/context/FeatureFlag";
import useUiPermissions, {
  type UiPermission,
} from "src/hooks/useUiPermissions";

export interface ProtectedRouteProps {
  permission?: UiPermission;
  feature?: keyof FeatureFlags;
}

const ProtectedRoute = ({ permission, feature }: ProtectedRouteProps) => {
  const permissions = useUiPermissions();
  const flags = useFeatureFlags();

  if (permission != null && !permissions.includes(permission)) {
    return <Navigate to="/" />;
  } else if (feature != null && !flags[feature]) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoute;
