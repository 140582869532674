import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Button } from "antd";

export interface ExpandButtonProps {
  isExpanded?: boolean;
  onClick?: () => void;
}

const ExpandButton = ({ isExpanded, onClick }: ExpandButtonProps) => (
  <Button
    type="text"
    shape="circle"
    size={isExpanded ? "large" : "small"}
    onClick={onClick}
    icon={
      isExpanded ? (
        <FullscreenExitOutlined className="text-navy-7 text-2xl" />
      ) : (
        <FullscreenOutlined className="text-navy-7 text-base" />
      )
    }
  />
);

export default ExpandButton;
