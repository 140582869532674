export const ROUTES = {
  homepage: "/",
  docs: "/docs",
  public_docs: "/public-docs",
  download_hub: "/download-hub",
  norovirus: "/infectious-diseases/gastrointestinal/norovirus",
  covid: "/infectious-diseases/respiratory/sars-cov-2",
  acetylfentanyl: "/substances/acetylfentanyl",
  buprenorphine: "/substances/buprenorphine",
  carfentanil: "/substances/carfentanil",
  cocaine: "/substances/cocaine",
  codeine: "/substances/codeine",
  dihydrocodeine: "/substances/dihydrocodeine",
  fentanyl: "/substances/fentanyl",
  heroin: "/substances/heroin",
  hydrocodone: "/substances/hydrocodone",
  hydromorphone: "/substances/hydromorphone",
  methadone: "/substances/methadone",
  methamphetamine: "/substances/methamphetamine",
  morphine: "/substances/morphine",
  naloxone: "/substances/naloxone",
  nicotine: "/substances/nicotine",
  oxycodone: "/substances/oxycodone",
  oxymorphone: "/substances/oxymorphone",
  sufentanil: "/substances/sufentanil",
  tianeptine: "/substances/tianeptine",
  tramadol: "/substances/tramadol",
  xylazine: "/substances/xylazine",
  customer_config: "/customers",
  notification_log: "/notifications",
  feature_flags: "/feature-flags",
  rsv: "/infectious-diseases/respiratory/rsv",
  flu: "/infectious-diseases/respiratory/influenza",
};
