import covidImage from "src/assets/images/covid_virus.png";
import { useFeatureFlags } from "src/context/FeatureFlag";

import DownloadCard from "./DownloadCard";
import type { LinkComponentProps } from "./links";

const datasets = [
  "covidNWSS",
  "covidCDC",
  "covidKits",
  "covidCountyInterpolated",
  "covidCountyAverage",
  "covidStateAverage",
  "covidRegionalAverage",
  "covidNationalAverage",
  "covidVariants",
] as const;

interface CovidDownloadsProps extends LinkComponentProps<typeof datasets> {
  className?: string;
}

const CovidDownloads = (props: CovidDownloadsProps) => {
  const flags = useFeatureFlags();
  return (
    <DownloadCard
      {...props}
      datasets={
        flags.covidVariantsUi
          ? datasets
          : datasets.filter((x) => x !== "covidVariants")
      }
      title="SARS-CoV-2"
      image={covidImage}
    />
  );
};

export default CovidDownloads;
